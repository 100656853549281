import React from 'react';
import styles from './index.module.css';
import {Link} from "react-router-dom";

const HeaderLink = ({to, text}) => {
    return (
        <Link to={to} target={"_blank"} className={styles.container}>
            {text}
        </Link>
    );
}

export default HeaderLink;
