import React from 'react';
import styles from './index.module.css';
import useAnimatedText from "./useAnimatedText";

const AnimatedText = ({ text, speed }) => {
    const animatedText = useAnimatedText(text, speed);

    return <span className={styles.text}>{animatedText}</span>;

}

export default AnimatedText;
