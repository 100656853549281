import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const filterLogsByDate = (log, date = dayjs()) => {
    const logDate = dayjs(log.timestamp);
    return logDate.isSame(date, 'day');
};

const logsSlice = createSlice({
    name: 'logs',
    initialState: {
        logs: [],
        error: null,
        socketConnected: false,
    },
    reducers: {
        setLogs: (state, action) => {
            state.logs = action.payload.filter(log => filterLogsByDate(log));
        },
        addLog: (state, action) => {
            if (filterLogsByDate(action.payload)) {
                state.logs.unshift(action.payload);
            }
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSocketConnected: (state, action) => {
            state.socketConnected = action.payload;
        },
    },
});

export const { setLogs, addLog, setError, setSocketConnected } = logsSlice.actions;
export default logsSlice.reducer;