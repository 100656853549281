import React from 'react';
import styles from './index.module.css';
import Footer from "../Footer/Footer";

const Element = ({tech}) => {
    return(
        <div className={styles.element}>
            <span>{tech}</span>
        </div>
    )
}

const HomeDescription = () => {
    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <h1>Welcome to my Personal API</h1>
                <span>The way I handle my data</span>
                <div className={styles.made}>
                    <span>Made with</span>
                    <div className={styles.list}>
                        <Element tech={'React.js'}/>
                        <Element tech={'Nest.ts'}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default HomeDescription;
