import React from 'react';
import styles from './index.module.css';

const Footer = () => {
    return (
        <footer className={styles.container}>
            2020-2024 © by krvvko
        </footer>
    );
}

export default Footer;
