import React from 'react';
import styles from './index.module.css';

const Log = ({url, date}) => {
    const formattedDate = new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });

    return (
        <div className={styles.container}>
            <span className={styles.url}>{url}</span>
            <span className={styles.date}>{formattedDate}</span>
        </div>
    );
}

export default Log;
