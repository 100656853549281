import {useGetPersonalDataQuery} from "./redux/api/personalApi";
import {useEffect} from "react";
import Hider from "./components/ui/Hider/Hider";
import Header from "./components/ui/Header/Header";
import Home from "./components/pages/Home/Home";

const App = () => {
    const { data } = useGetPersonalDataQuery();
    useEffect(() => {
        import('./redux/api/webSocketManager');
    }, [])
    return (
        <>
            <Hider />
            {data && <>
                <Header />
                <Home />
            </>}
        </>
    );
}

export default App;