import React from 'react';
import styles from './index.module.css';
import {useSelector} from "react-redux";
import Log from "./Log/Log";

const HomeSocketData = ({height}) => {
    const logs = useSelector((state) => state.logs.logs);
    const error = useSelector((state) => state.logs.error);

    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.container} style={{height: `${height}px`}}>
            <div className={styles.top}>
                <span>Last Logs</span>
            </div>
            <div className={styles.list}>
                {logs.map((log) => (
                    <Log key={log.id || log.timestamp} url={log.url} date={log.date_time} />
                ))}
            </div>
        </div>
    );
}

export default HomeSocketData;
