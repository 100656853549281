import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.css';
import HomeDescription from "../../ui/HomeDescription/HomeDescription";
import HomeSocketData from "../../ui/HomeSocketData/HomeSocketData";

const Home = () => {
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setContainerHeight(containerRef.current.offsetHeight);
        }
        const handleResize = () => {
            if (containerRef.current) {
                setContainerHeight(containerRef.current.offsetHeight);
            }
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div ref={containerRef} className={styles.container}>
            <HomeDescription />
            <HomeSocketData height={containerHeight} />
        </div>
    );
}

export default Home;
