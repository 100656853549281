import React from 'react';
import styles from './index.module.css';
import shrimp from './../../../media/svg/shrimp.svg';
import HeaderLink from "./HeaderLink/HeaderLink";
import {useGetPersonalDataQuery} from "../../../redux/api/personalApi";

const Header = () => {
    const { data } = useGetPersonalDataQuery();
    return (
        <header className={styles.container}>
            <div className={styles.logo}>
                <img src={shrimp} alt=""/>
                <span>API</span>
            </div>
            <div className={styles.links}>
                <HeaderLink text={'API DOCS'} to={'/api/docs'} />
                {Object.entries(data.links).map(([key, value], index) => (
                    <HeaderLink key={index} text={key} to={value} />
                ))}
            </div>
        </header>
    );
}

export default Header;
