import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { useGetPersonalDataQuery } from "../../../redux/api/personalApi";
import { useSelector } from "react-redux";
import AnimatedText from "../AnimatedText/AnimatedText";

const Hider = () => {
    const { isLoading } = useGetPersonalDataQuery();
    const socketConnected = useSelector(state => state.logs.socketConnected);
    const [texts, setTexts] = useState([]);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!isLoading && socketConnected) {
            setTimeout(() => {
                updateTexts('Loading success!');
                setTimeout(() => {
                    setReady(true)
                }, 1500)
            }, 3500)
        }
        if (isLoading && !texts.includes('Fetching data')) {
            updateTexts('Fetching data');
        }
        if (!socketConnected && !texts.includes('Connecting to socket')) {
            setTimeout(() => {
                updateTexts('Connecting to socket');
            }, 1500)
        }
    }, [isLoading, socketConnected, texts]);

    const updateTexts = (newText) => {
        setTexts(texts => texts[texts.length - 1] === newText ? texts : [...texts, newText]);
    };

    return (
        <div className={`${styles.container} ${ready ? styles.ready : ''}`}>
            {texts.map((text, index) => (
                <AnimatedText key={index} text={text} speed={15} />
            ))}
        </div>
    );
};

export default Hider;
