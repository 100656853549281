import { configureStore } from '@reduxjs/toolkit';
import {personalApi} from "../api/personalApi";
import logsSocket from "../api/logsSocket";

export const store = configureStore({
    reducer: {
        [personalApi.reducerPath]: personalApi.reducer,
        logs: logsSocket,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(personalApi.middleware),
});
