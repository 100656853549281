import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const personalApi = createApi({
    reducerPath: 'personalApi',
    baseQuery: fetchBaseQuery({ baseUrl: window.location.protocol + "//" + window.location.hostname }),
    endpoints: (builder) => ({
        getPersonalData: builder.query({
            query: () => '/api/personal',
        }),
    }),
});

export const { useGetPersonalDataQuery } = personalApi;
