import { useState, useEffect } from 'react';

const useAnimatedText = (text, speed = 100, callback) => {
    const [displayedText, setDisplayedText] = useState('');
    useEffect(() => {
        let currentText = '';
        let i = 0;
        let step = 0;
        const characters = '!@#$%^&*()_+{}:"<>?|[];\',./';

        const intervalId = setInterval(() => {
            if (i < text.length) {
                if (step < 2 + Math.floor(Math.random() * 4)) {
                    currentText = text.slice(0, i) + characters.charAt(Math.floor(Math.random() * characters.length));
                    setDisplayedText(currentText);
                    step++;
                } else {
                    currentText = text.slice(0, i + 1);
                    setDisplayedText(currentText);
                    i++;
                    step = 0;
                }
            } else {
                clearInterval(intervalId);
                callback && callback();
            }
        }, speed);

        return () => clearInterval(intervalId);
    }, [text, speed, callback]);

    return displayedText;
};

export default useAnimatedText;